@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'IvyPresto-Thin';
  src: url('./assets/fonts/ivy-presto/IvyPresto-Thin.otf');
}
@font-face {
  font-family: 'IvyPresto-Light';
  src: url('./assets/fonts/ivy-presto/IvyPresto-Light.otf');
}
@font-face {
  font-family: 'IvyPresto-Regular';
  src: url('./assets/fonts/ivy-presto/IvyPresto-Display-Regular.otf');
}

body {
  font-family: 'Poppins', Sans-Serif;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
}

.active-svg svg {
  @apply !w-6 !h-6 fill-current text-white;
}
