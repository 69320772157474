@tailwind base;
@tailwind components;
@tailwind utilities;

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
}

/* custom css */
.form-control.round-b-xl .ck-content {
  @apply !rounded-t-none !rounded-b-xl;
}

.form-control.border-form .ck-content,
.form-control.border-form .ck.ck-toolbar {
  @apply !border-hazel-nude-200;
}
